import CheckIcon from "../images/icon/check.svg";
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/slides/general-page/header.jpg";
import Slide1 from "../images/slides/general-page/slide01.png";
import Slide2 from "../images/slides/general-page/slide02.png";
import Slide3 from "../images/slides/general-page/slide03.png";
import Slide4 from "../images/slides/general-page/slide04.png";
import Slide5 from "../images/slides/general-page/slide05.png";
import Slide6 from "../images/slides/general-page/slide06.png";
import Slide7 from "../images/slides/general-page/slide07.png";
import Slide8 from "../images/slides/general-page/slide08.png";


export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("general-page.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("general-page.topSubtitle"),
        colorClass: "colorLight",
      },
      cta: {
        text: t("general-page.joinFree"),
        url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
        type: "mainColor",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("general-page.imgAlt"),
      },
    },
    pageContent: [
      {
        info: {
          title: {
            text: t("general-page.findManufacturer"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [
              t("general-page.accessNetwork"),
              t("general-page.saveTime"),
              t("general-page.viewProfiles"),
              t("general-page.inviteTeam"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: true,
          topDiagonal: true,
          backgroundGrey: false,
          customPB: true,
          customPBText: true,
          images: [Slide1, Slide2, Slide3],
        },
        isTextFirst: true,
        cta: {
          text: t("general-page.exploreNetwork"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
      {
        info: {
          title: {
            text: t("general-page.exploreShowrooms"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [
              t("general-page.viewSamples"),
              t("general-page.accessCatalogues"),
              t("general-page.fineFilters"),
              t("general-page.addFavourites"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: true,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: false,
          customPBText: true,
          images: [Slide4, Slide5],
        },
        isTextFirst: false,
        cta: {
          text: t("general-page.getQuotes"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
      {
        info: {
          title: {
            text: t("general-page.requestQuotes"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [
              t("general-page.createRFQs"),
              t("general-page.receiveOffers"),
              t("general-page.manageSelection"),
              t("general-page.reduceTimes"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: false,
          topDiagonal: true,
          backgroundGrey: false,
          customPB: true,
          customPBText: true,
          images: [Slide6, Slide7],
        },
        isTextFirst: true,
        cta: {
          text: t("general-page.seeProducts"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
      {
        info: {
          title: {
            text: t("general-page.sourceSustainably"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [
              t("general-page.sustainableSuppliers"),
              t("general-page.complianceCertificates"),
              t("general-page.trackValidity"),
              t("general-page.reviewingVerified"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: true,
          topDiagonal: true,
          customPB: true,
          customPBText: true,
          backgroundGrey: true,
          images: [Slide7, Slide8],
        },
        isTextFirst: false,
        cta: {
          text: t("general-page.seeProducts"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
    ],


    aboutUs: {
      title: {
        text: t("face-masks-and-medical-wear.aboutUs"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("face-masks-and-medical-wear.aboutUsText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
  };
};
